import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { getImageUrl } from "../common/Funcation";
import { USER_API } from "../common/common";
import { getApiLink, notifyError, postApi } from "../service";
import Logo from '../assets/images/logo.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
const cookies = new Cookies();

export default function Signup(this: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const DD = useSelector((e: any) => e.domainDetails);
  const [domainDetails, setDomainDetails] = useState(DD);
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [, updateState] = React.useState({});
  const [isValidationWrong, setIsValidationWrong] = useState(false);
  const [isError, setIsError] = useState({ flag: false, msg: "" });
  const [isPassShow, setIsPassShow] = useState(false);
  const [isPassConfShow, setIsPassConfShow] = useState(false);

  const [confirmationCode, setConfirmationCode] = useState(
    Math.floor(1000 + Math.random() * 9000)
  );

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    validationCode: "",
    mobileNumber: "",
    currency: "BDT",
    firstName: "",
    email: "",
    refferCode: "",
  });
  useEffect(() => {
    setDomainDetails(DD);
    return () => { };
  }, [DD]);

  useEffect(() => {
    document.documentElement.style.setProperty("--bodyBackGround", `#111111`);
  }, []);

  const handelInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsValidationWrong(false);
    setFormData({ ...formData, [name]: value });
  };

  const handelSubmit = async (e: any) => {
    console.log("click :: ", formData);
    e.preventDefault();
    // return false;
    if (
      Validator.current.allValid() &&
      confirmationCode === parseInt(formData.validationCode) &&
      formData.username !== "" &&
      formData.password !== "" &&
      formData.confirmPassword !== "" &&
      formData.mobileNumber !== "" &&
      formData.firstName !== "" &&
      formData.email !== "" &&
      formData.refferCode !== "" &&
      formData.password === formData.confirmPassword
    ) {
      setIsValidationWrong(false);
      const ipDetails = await getApiLink({
        api: "https://ipapi.co/json/?key=wfzfdfQ4cUsaTVURUkj2oF6L51Y4jNE0IM2yE0V2xMyMkxjTsr",
      });
      console.log("call ip:  ipDetails :", ipDetails);

      let data = {
        api: USER_API.SIGN_UP,
        value: {
          user_name: formData.username,
          password: formData.password,
          confirmPassword: formData.confirmPassword,
          mobileNumber: formData.mobileNumber,
          currency: formData.currency,
          firstName: formData.firstName,
          email: formData.email,
          refferCode: formData.refferCode,
          domain: window.location.hostname,
          // domain: "velki777.live",
          city: ipDetails?.data?.city,
          state: ipDetails?.data?.region,
          country: ipDetails?.data?.country_name,
          ISP: ipDetails?.data?.org,
        },
      };
      await postApi(data)
        .then(function (loginData) {
          if (loginData) {
            //  notifyMessage(loginData.data.message)

            console.log("::::::::;", loginData);
            let HeaderData = {
              ...loginData.data.data.roleAccess,
              user_name: loginData.data.data.user_name,
              remaining_balance: loginData.data.data?.balance,
              ...loginData.data.data,
            };
            dispatch({ type: 'HEADER_DETAILS', payload: HeaderData })
            cookies.set("skyTokenFront", loginData.data.data.token, {
              domain: process.env.REACT_APP_COOKIE_DOMAIN,
              path: "/",
            });
            dispatch({
              type: "AUTHENTICATION",
              payload: {
                isLogin: true,
                token: loginData.data.data.token,
                changePassword: loginData.data.data.newPassword,
              },
            });

            window.location.reload();
            // navigate("/");
            // setOpenModal(false);
            if (loginData.data.data.newPassword === true) {
              navigate("change/password");
            }
          }
        })
        .catch((err) => {
          notifyError(err.response.data.message);
          setIsError({
            flag: true,
            msg: err.response.data.message,
          });
          setIsValidationWrong(false);
          setConfirmationCode(Math.floor(1000 + Math.random() * 9000));
          setFormData({ ...formData, validationCode: "" });
        });
    } else {
      console.log("else");

      Validator.current.showMessages();
      if (confirmationCode !== parseInt(formData.validationCode)) {
        setIsValidationWrong(true);
      }
      forceUpdate();
      setConfirmationCode(Math.floor(1000 + Math.random() * 9000));
    }
  };

  const Validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: this,
    })
  );
  return (
    <>
      <div className="login-title">
        <div className="center">
          <p>Sign Up</p>
        </div>
        <div className="back" onClick={() => navigate("/")}>
          <FontAwesomeIcon icon={faClose} />
        </div>
      </div>
      <div className="login-page-container">
        <div className="logo">
          {/* <img src={getImageUrl(DD?.logo)} alt="" /> */}
          <img src={Logo} alt="" />
          {/* <img src="https://ballbet.live/source/icon/nav-logo.png" /> */}
        </div>
        <form className="ng-pristine ng-invalid ng-touched">
          <div className="menu-box">
            <div className="input-group ng-star-inserted"><label>Username</label><input type="text"
              className="input ng-pristine ng-invalid ng-touched" name="userId"
              placeholder="4-15 Characters or Numbers" /><input type="button" className="clear" /></div>
            <div className="input-group password">
              <div className="eyes"></div><label>Password</label><input type="password"
                className="input ng-untouched ng-pristine ng-invalid" name="password"
                placeholder="6-20 Characters and Numbers" /><input type="button" className="clear" />
            </div>
          </div>
        </form>
        <form className="ng-pristine ng-invalid ng-touched">
          <div className="menu-box">
            <div className="input-group ng-star-inserted">
              <label>Phone Number</label>
              <div className="d-flex">
                <div className="country-code d-flex align-items-center flex-shrink-0">
                  <img src="https://img.m156b.com/mb/h5/assets/images/flag/PH.png?v=1732694025787&amp;source=mcdsrc" width={20} height={20} /><span>+63</span>
                </div>
                <input type="text" className="input ng-pristine ng-invalid ng-touched" name="userId"
                  placeholder="Phone Number" /><input type="button" className="clear" />
              </div>
            </div>

          </div>
        </form>
        <div className="login-button">Confirm</div>
        <p className="button-tips mb-0"><span>Already a member ? </span><a href="#" onClick={() => navigate("/loin")}>Sign up</a></p>
        <p className="button-tips mt-0"><span> Registering means you are over 18 years old, have read and agree to the </span><a href="#">Terms & Conditions.</a></p>
      </div>
    </>
  );
}
