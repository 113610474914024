import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Referral() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Share");
  return (
    <>
      <div className="mob-header">
        <div className="back" onClick={() => navigate("/")}>
          <FontAwesomeIcon icon={faClose} />
        </div>
        <div className="center">
          <h5>Referral</h5>
        </div>
        <div className="help">
          <img
            src="../../images/extra-icon/message.png"
            alt="Help"
            height={25}
          />
          <span>Help</span>
        </div>
      </div>

      <div className="slide-btns-box">
        <div className="slide-btns">
          <div
            className={`slide-btn-left ${activeTab === "Share" ? "active" : ""
              }`}
            id="slide-btn-left"
            onClick={() => setActiveTab("Share")}
          >
            Share
          </div>
          <div
            className={`slide-btn-right ${activeTab === "Rewards" ? "active" : ""
              }`}
            id="slide-btn-right"
            onClick={() => setActiveTab("Rewards")}
          >
            Rewards
          </div>
        </div>
      </div>
      {activeTab === "Share" && (
        <div className="panel1">
          <div className="gift-box">
            <div className="gift-pelatte">
              <img
                id="gift-icon"
                src={"../../images/extra-icon/gift-box.png"}
                style={{ height: "50px" }}
              />
            </div>
            <br />
            <br />
            <br />

            <div className="bx">
              <div className="subBx1">
                <div className="subsubBx1" id="subsubBx1">
                  <input type="text" id="textToCopy" value="265076" />
                </div>
                <div className="subsubBx2" id="subsubBx2">
                  <img src={"../../images/extra-icon/copy.png"} />
                </div>
              </div>
              <div className="subBx2">Share</div>
            </div>
          </div>
          <div className="referral-status-box">
            <div style={{ padding: "6px" }}></div>
            <div className="ref-title">Your referral status</div>
            <div className="ref-points">
              <div className="ref-point1">
                <div className="point1">0</div>
                <div>Friend's Accepted</div>
              </div>
              <div className="ref-point2">
                <div className="point2">0</div>
                <div>Friend's Complete</div>
              </div>
            </div>
          </div>
        </div>
      )}

      {activeTab === "Rewards" && (
        <div className="panel1">
          <div className="records-list">
            <div className="empty-records">
              <div>
                <img
                  src={"../../images/extra-icon/notebook.png"}
                  height="100px"
                />
                <div>No Data</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
