import React, { useEffect, useState } from "react";
import Pay1 from "../assets/images/pay16.png";
import Pay2 from "../assets/images/pay22.png";
import Pay3 from "../assets/images/pay33.png";
import Pay4 from "../assets/images/pay34.png";
import Pay5 from "../assets/images/pay45.png";
import Pay6 from "../assets/images/pay59.png";
import Pay7 from "../assets/images/pay60.png";
import Pay8 from "../assets/images/pay61.png";
import Pay9 from "../assets/images/pay47.png";
import Gamcare from "../assets/images/gamcare.png";
import AgeLimite from "../assets/images/age-limit.png";
import Regulations from "../assets/images/regulations.png";
import Facebook from "../assets/images/facebook.png";
import Instagram from "../assets/images/instagram.png";
import Twitter from "../assets/images/twitter.png";
import pinterest from "../assets/images/pinterest.png";
import youtube from "../assets/images/youtube.png";
import Telegram from "../assets/images/telegram-channel.png";
import GamingLicence from "../assets/images/gaming_license.png";
import AndroidEn from "../assets/images/android-en.png";
import BD from "../assets/images/BD.png";
import Logo from "../assets/images/nav-logo.png";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ADMIN_API } from "../common/common";
import { postApiAdmin } from "../service";
import { getImageUrl, Logout } from "../common/Funcation";
import { faEye, faEyeSlash, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { url } from "inspector";
import IconBonuses from "../assets/images/icon-bonuses.svg";
import IconWithdrawal from "../assets/images/icon-withdrawal.svg";
import IconDeposit from "../assets/images/icon-deposit.svg";
import iconBetRecords from "../assets/images/icon-bet-records.svg";
import IconTurnover from "../assets/images/icon-turnover.svg";
import IconRecords from "../assets/images/icon-records.svg";
import IconInfo from "../assets/images/icon-info.svg";
import IconResetpasswords from "../assets/images/icon-resetpasswords.svg";
import IconInbox from "../assets/images/icon-inbox.svg";
import IconReferral from "../assets/images/icon-referral.svg";
import IconWwitches from "../assets/images/icon-switches.svg";
import IconFacebook from "../assets/images/icon-facebook.svg";
import IconInstagram from "../assets/images/icon-instagram.svg";
import IconTwitter from "../assets/images/icon-twitter.svg";
import IconTelegram from "../assets/images/icon-telegram.svg";
import IconPinterest from "../assets/images/icon-pinterest.svg";
import IconAbout from "../assets/images/icon-about.svg";
import IconWhatsapp from "../assets/images/icon-whatsapp.svg";
import IconFacebookMessenger from "../assets/images/icon-facebook-messenger.svg";
import IconEmail from "../assets/images/icon-email.svg";

const cookies = new Cookies();
const FooterComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((e: any) => e.isAuthenticated);
  const HeaderData = useSelector((e: any) => e.Header);
  const [menuTab, setMenuTab] = useState<string>("home");
  const DD = useSelector((e: any) => e.domainDetails);
  const [domainDetails, setDomainDetails] = useState(DD);
  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    setDomainDetails(DD);
  }, [DD]);
  const userId = HeaderData?._id;
  const goToLogin = () => {
    navigate("/login");
  };
  const openMenu = (name: string) => {
    console.log("name :: ", name);
    console.log("menuTab :: befor: ", menuTab);

    // if (name === menuTab) return false;
    if (name === "myaccount") {
      console.log("hhhhh");

      setMenuTab("myaccount");
      // navigate("/account-panal");
    } else if (name === "home") {
      console.log("dfgdfhdg");
      setMenuTab("home");
      navigate("/");
    } else if (name === "deposit") {
      setMenuTab("deposit");
      navigate("/deposit");
    } else if (name === "promotion") {
      console.log("set Menu promp");

      setMenuTab("promotion");
      navigate("/promotion");
    }
  };
  const [pageData, setPageData] = useState<any>({});
  useEffect(() => {
    if (menuTab === "myaccount") {
      // nothing
    } else if (window.location.pathname === "/deposit") {
      setMenuTab("deposit");
    } else if (window.location.pathname === "/promotion") {
      setMenuTab("promotion");
    }
  });
  useEffect(() => {
    console.log("Hell", menuTab);

    //'/admin/downLineList'
    if (menuTab === "myaccount") {
      console.log("isAuthenticated ::", isAuthenticated);

      if (isAuthenticated?.isLogin) {
        getPageData(isAuthenticated.token);
      }
    }
    return () => { };
  }, [menuTab, isAuthenticated]);
  const getPageData = async (token: any = undefined) => {
    let data: any = {
      api: userId && ADMIN_API.PLAYER_GET_PROFILE,
      value: { id: userId },
      token: token ? token : undefined,
    };

    await postApiAdmin(data)
      .then(function (response) {
        console.log(response);
        setPageData(response.data.data);
        dispatch({ type: "USER_DATA", payload: response.data.data });
      })
      .catch((err) => {
        if (err?.response?.data?.statusCode === 401) {
          dispatch({
            type: "AUTHENTICATION",
            payload: { isLogin: false, token: "", changePassword: false },
          });
          Logout();
          navigate("/login");
        }
      });
  };
  const HandleLogOut = (e: any) => {
    e.preventDefault();
    Logout(e);
    dispatch({
      type: "AUTHENTICATION",
      payload: { isLogin: false, token: "" },
    });
    navigate("/login");
  };
  return (
    <>
      <footer className="footer ng-star-inserted">
        <div className="footer-top">
          <div className="ambassadors">
            <div className="ng-star-inserted">
              <h2 className="ng-star-inserted">Brand Ambassador</h2>
              <ul className="ng-star-inserted mb-0">
                <li className="ng-star-inserted">
                  <img alt="undefined"
                    src="https://img.m156b.com/mb/h5/assets/images/light/footer/ambassadors-quinton-de-kock.png?v=1732694025787&amp;source=mcdsrc"
                    loading="lazy" className="ng-star-inserted" />
                  <p className="ng-star-inserted">Quinton de
                    Kock</p>
                  <strong className="ng-star-inserted">South
                    African Cricketer</strong>
                </li>
                <li className="ng-star-inserted">
                  <img alt="undefined"
                    src="https://img.m156b.com/mb/h5/assets/images/light/footer/ambassadors-monami-ghosh.png?v=1732694025787&amp;source=mcdsrc"
                    loading="lazy" className="ng-star-inserted" />
                  <p className="ng-star-inserted">Monami Ghosh</p>
                  <strong className="ng-star-inserted">Film
                    &amp; Television Superstar</strong>
                </li>
                <li className="ng-star-inserted">
                  <img alt="undefined"
                    src="https://img.m156b.com/mb/h5/assets/images/light/footer/ambassadors-david-de-gea.png?v=1732694025787&amp;source=mcdsrc"
                    loading="lazy" className="ng-star-inserted" />
                  <p className="ng-star-inserted">David De Gea</p>
                  <strong className="ng-star-inserted">Spanish
                    Professional Footballer</strong>
                </li>
                <li className="ng-star-inserted">
                  <img alt="undefined"
                    src="https://img.m156b.com/mb/h5/assets/images/light/footer/ambassadors-sunny-leone.png?v=1732694025787&amp;source=mcdsrc"
                    loading="lazy" className="ng-star-inserted" />
                  <p className="ng-star-inserted">Sunny Leone</p>
                  <strong className="ng-star-inserted">Actress</strong>
                </li>
              </ul>
            </div>
          </div>
          <div className="official-partner">
            <div style={{ display: "contents" }}>
              <h2 className="ng-star-inserted">Official Partner</h2>
              <ul className="ng-star-inserted mb-0">
                <li className="ng-star-inserted">
                  <a target="_blank"
                    href="https://www.heyvip.com" className="ng-star-inserted">
                    <img alt="undefined"
                      src="https://img.m156b.com/mb/h5/assets/images/footer/white/official-partner-heyvip.png?v=1732694025787&amp;source=mcdsrc"
                      loading="lazy" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div style={{ display: "contents" }}>
            <div className="footer-social ng-star-inserted">
              <h2>Community Websites</h2>
              <ul className="mb-0">
                <li className="ng-star-inserted">
                  <a target="_blank"
                    href="https://www.instagram.com/bj_php/" className="ng-star-inserted">
                    <img alt="instagram"
                      src="https://img.m156b.com/mb/h5/assets/images/footer/socialicons/instagram.svg?v=1732694025787&amp;source=mcdsrc"
                      loading="lazy" />
                  </a>
                </li>
                <li className="ng-star-inserted">
                  <a target="_blank"
                    href="https://x.com/BetjiliPHP" className="ng-star-inserted">
                    <img alt="twitter"
                      src="https://img.m156b.com/mb/h5/assets/images/footer/socialicons/twitter.svg?v=1732694025787&amp;source=mcdsrc"
                      loading="lazy" />
                  </a>
                </li>
                <li className="ng-star-inserted">
                  <a target="_blank"
                    href="https://www.facebook.com/BetjiliNewOfficialPage/"
                    className="ng-star-inserted">
                    <img alt="facebook"
                      src="https://img.m156b.com/mb/h5/assets/images/footer/socialicons/facebook.svg?v=1732694025787&amp;source=mcdsrc"
                      loading="lazy" />
                  </a>
                </li>
                <li className="ng-star-inserted">
                  <a target="_blank"
                    href="https://t.me/betjiliPHPofficial" className="ng-star-inserted">
                    <img alt="telegram-channel"
                      src="https://img.m156b.com/mb/h5/assets/images/footer/socialicons/telegram-channel.svg?v=1732694025787&amp;source=mcdsrc"
                      loading="lazy" />
                  </a>
                </li>
                <li className="ng-star-inserted">
                  <a target="_blank"
                    href="https://in.pinterest.com/Bet_jili/" className="ng-star-inserted">
                    <img alt="pinterest"
                      src="https://img.m156b.com/mb/h5/assets/images/footer/socialicons/pinterest.svg?v=1732694025787&amp;source=mcdsrc"
                      loading="lazy" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="pay">
            <div className="pay ng-star-inserted">
              <h2>Payment Methods</h2>
              <ul className="mb-0">
                <li className="ng-star-inserted">
                  <img alt="pay16"
                    src="https://img.m156b.com/mb/h5/assets/images/footer/black/pay16.png?v=1732694025787&amp;source=mcdsrc"
                    loading="lazy" className="ng-star-inserted" />
                </li>
                <li className="ng-star-inserted">
                  <img alt="pay41"
                    src="https://img.m156b.com/mb/h5/assets/images/footer/black/pay41.png?v=1732694025787&amp;source=mcdsrc"
                    loading="lazy" className="ng-star-inserted" />
                </li>
                <li className="ng-star-inserted">
                  <img alt="pay42"
                    src="https://img.m156b.com/mb/h5/assets/images/footer/black/pay42.png?v=1732694025787&amp;source=mcdsrc"
                    loading="lazy" className="ng-star-inserted" />
                </li>
                <li className="ng-star-inserted">
                  <img alt="pay15"
                    src="https://img.m156b.com/mb/h5/assets/images/footer/black/pay15.png?v=1732694025787&amp;source=mcdsrc"
                    loading="lazy" className="ng-star-inserted" />
                </li>
                <li className="ng-star-inserted">
                  <img alt="pay44"
                    src="https://img.m156b.com/mb/h5/assets/images/footer/black/pay44.png?v=1732694025787&amp;source=mcdsrc"
                    loading="lazy" className="ng-star-inserted" />
                </li>
                <li className="ng-star-inserted">
                  <img alt="pay64"
                    src="https://img.m156b.com/mb/h5/assets/images/footer/black/pay64.png?v=1732694025787&amp;source=mcdsrc"
                    loading="lazy" className="ng-star-inserted" />
                </li>
                <li className="ng-star-inserted">
                  <img alt="pay66"
                    src="https://img.m156b.com/mb/h5/assets/images/footer/black/pay66.png?v=1732694025787&amp;source=mcdsrc"
                    loading="lazy" className="ng-star-inserted" />
                </li>
              </ul>
            </div>
          </div>
          <div className="about-us">
            <div className="about-us ng-star-inserted">
              <h2>About Us</h2>
              <ul className="mb-0">
                <li className="ng-star-inserted">
                  <a target="_blank"
                    href="https://betjiliguide.com/ph-en/ph-en/" className="ng-star-inserted">
                    About Us </a>
                </li>
                <li className="ng-star-inserted">
                  <a target="_blank"
                    href="https://betjiliguide.com/ph-en/legal-privacy-policy/"
                    className="ng-star-inserted"> Privacy Policy </a>
                </li>
                <li className="ng-star-inserted">
                  <a target="_blank"
                    href="https://betjiliguide.com/ph-en/terms-conditions-3/"
                    className="ng-star-inserted"> Terms &amp; Conditions </a>
                </li>
                <li className="ng-star-inserted">
                  <a target="_blank"
                    href="https://betjiliguide.com/ph-en/rules-regulations/"
                    className="ng-star-inserted"> Rules &amp; Regulation </a>
                </li>
                <li className="ng-star-inserted">
                  <a target="_blank"
                    href="https://betjiliguide.com/ph-en/responsible-gaming-duty/"
                    className="ng-star-inserted"> Responsible Gaming </a>
                </li>
                <li className="ng-star-inserted">
                  <a target="_blank"
                    href="https://betjiliguide.com/ph-en/faq-en/" className="ng-star-inserted">
                    Frequently Asked Questions </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="logoarea">
            <div className="logoarea__copyright"> © 2024 Betjili Copyrights. All Rights Reserved </div>
          </div>
        </div>
      </footer>
      {(!cookies.get("skyTokenFront") && false) && (
        <div className="footer-btn" id="footer-unloged-button">
          <div className="button1">
            <img src={BD} />
            BDT
            <br />
            English
          </div>
          <div
            className="button2"
            id="signupbtn"
            onClick={() => navigate("/signup")}
          >
            Sign up
          </div>
          <div className="button3" id="loginbtn" onClick={goToLogin}>
            Login
          </div>
        </div>
      )}
      {cookies.get("skyTokenFront") && (
        <div className="logged-footer-btn" style={{display:"none"}}>
          <div
            className={`logged-btn ${menuTab === "home" ? "b-active" : ""}`}
            id="home"
            onClick={() => openMenu("home")}
          >
            {/* <img src="../../images/extra-icon/home.png" /> */}
            {/* <img src="../../images/extra-icon/icon-home.svg" /> */}
            <img src="../../images/extra-icon/toolbar-icon-home.svg" />
            Home
          </div>
          <div
            className={`logged-btn ${menuTab === "promotion" ? "b-active" : ""
              }`}
            id="promotion"
            onClick={() => openMenu("promotion")}
          >
            {/* <img src="../../images/extra-icon/promotion.png" /> */}
            <img src="../../images/extra-icon/toolbar-icon-promotion.svg" />
            Promotions
          </div>
          <div
            className={`logged-btn ${menuTab === "deposit" ? "b-active" : ""}`}
            id="deposit"
            onClick={() => openMenu("deposit")}
          >
            {/* <img src="../../images/extra-icon/wallet.png" /> */}
            <img src="../../images/extra-icon/toolbar-icon-promotion.svg" />
            Deposit
          </div>
          <div
            className={`logged-btn ${menuTab === "myaccount" ? "b-active" : ""
              }`}
            id="myaccount"
            onClick={() => openMenu("myaccount")}
          >
            {/* <img src="../../images/extra-icon/user.png" /> */}
            <img src="../../images/extra-icon/toolbar-icon-deposit.svg" />
            My Account
          </div>
        </div>
      )}

      
      {/* <MyAccountPanal isActive={menuTab === "myaccount"} /> */}
    </>
  );
};

export default FooterComponent;
